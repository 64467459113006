import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import QuestionnaireComponent from "./components/QuestionnaireComponent"; // Import your Home component
import About from "./components/About"; // Import your About component

function App() {
  return (
    <QuestionnaireComponent/>
  );
}

export default App;