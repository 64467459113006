import React, { useState, useEffect } from 'react';
import { Alert, Container, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, CardActions, FormGroup, FormHelperText, Button, TextField, Checkbox, Typography, Box, Stepper, Step, StepLabel, Grid, RadioGroup, FormControlLabel, Radio, Paper, Snackbar } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';

const schema = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email('Enter a valid email').required('Email is required'),
    emailToConfirm: yup.string().email('Enter a valid email').required('Email is required')
        .oneOf([yup.ref('email')], 'Emails must match'),
    sendToDifferentEmail: yup.string(),
    differentEmail: yup.string().email('Enter a valid email')
      .when('sendToDifferentEmail', (sendToDifferentEmail, schema) => 
        sendToDifferentEmail == '0' 
        ? schema.required('Different Email is required')
          .notOneOf([yup.ref('email'), yup.ref('emailToConfirm')], 'Different Email cannot match the primary email or its confirmation')
        : schema.notRequired()
    ),
    differentEmailToConfirm: yup.string().email('Enter a valid email')
      .when('sendToDifferentEmail', (sendToDifferentEmail, schema) => 
        sendToDifferentEmail == '0' 
        ? schema.required('Different Email Confirmation is required')
          .oneOf([yup.ref('differentEmail')], 'Emails must match')
          .notOneOf([yup.ref('email'), yup.ref('emailToConfirm')], 'Different Email Confirmation cannot match the primary email or its confirmation')
        : schema.notRequired()
    ),
    instagram: yup.string().required('Instagram is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
    serviceHeard: yup.string().required('Service Heard is required'),
    gender: yup.string().required('Gender is required'),
    isPregnant: yup.number().when('gender', (gender, schema) => 
      gender === '1' ? schema.required('Is Pregnant is required') : schema.notRequired()
    ),
    isBreastfeeding: yup.number().when('gender', (gender, schema) => 
      gender === '1' ? schema.required('Is Breastfeeding is required') : schema.notRequired()
    ),
    knowsBodyFat: yup.string().required('Knows Body Fat is required'),
    bodyFatPercent: yup.number().when('knowsBodyFat', (knowsBodyFat, schema) => 
      knowsBodyFat === '0'
        ? schema.min(0.1, 'Body Fat Percentage must be greater than 0').required('Body Fat Percentage is required')
        : schema.notRequired()
    ),
    unit: yup.string().required('Unit of Measure is required'),
    height: yup.number().required('Height is required')
      /* .when('unit', (unit, schema) => schema.test({
        name: 'height-test',
        message: 'Height must be greater than 4 feet or the centimeter equivalent',
        test: (height) => {
          // Assuming the actual height value is available in the context where this test is applied
          if (!unit || !height) return false;
          if (unit === '0') { // Assuming '0' is for imperial
            return true;//height > 48; // Height in inches
          } else if (unit === '1') { // Assuming '1' is for metric
            return true // height > 121.92; // Height in centimeters
          }
          return false;
        }
      })) */,
    weight: yup.number().required('Weight is required')
      /* .when('unit', (unit, schema) => 
        unit === '0'
          ? schema.min(60, 'Weight must be greater than 60 lbs for imperial units')
          : schema.min(27.2155, 'Weight must be greater than 27.2155 kg for metric units')
      ) */,
    age: yup.number().required('Age is required').min(18, 'You must be at least 18 years old'),
    activityLevel: yup.string().required('Activity Level is required'),
    experienceLevel: yup.string().required('Experience Level is required'),
    workoutFrequency: yup.string().required('Workout Frequency is required'),
    carbsOrFat: yup.string().required('Carbs or Fat is required'),
    numberOfMeals: yup.string().required('Number of Meals is required'),
    doesKeto: yup.string().required('Does Keto is required'),
    isCarbSensitive: yup.string().required('Is Carb Sensitive is required'),
    prevCalories: yup.string().when('isCarbSensitive', (isCarbSensitive, schema) => 
      isCarbSensitive === '0'
        ? schema.min(1, 'Previous Calories must be greater than 0').required('Previous Calories is required')
        : schema.notRequired()
    ),
    goal: yup.string().required('Goal is required'),
    dietType: yup.string().required('Diet Type is required'),
    dietPace: yup.string().required('Diet Pace is required'),
    workoutTime: yup.string().required('Workout Time is required'),
    foodAllergies: yup.string().required('Food Allergies is required'),
    injuries: yup.string().required('Listing injuries is required'),
    medicalConditions: yup.string().required('Listing Medical Conditions is required'),
    accessToGym: yup.string().required('Listing Gym Access is required'),
    goalDescription: yup.string().required('Listing goal description is required'),
    favProtein: yup.array().of(yup.string()).min(5, 'Please select at least 5 favorite proteins').required('Listing Favorite Proteins is required'),
    favCarbs: yup.array().of(yup.string()).min(5, 'Please select at least 5 favorite carbohydrates').required('Listing favorite Carbohydrates is required'),
    favVeg: yup.array().of(yup.string()).min(5, 'Please select at least 5 favorite vegetables').required('Listing favorite vegetables is required'),
    favFat: yup.array().of(yup.string()).min(5, 'Please select at least 5 favorite fats').required('Listing favorite fats is required'),
    favCondiments: yup.array().of(yup.string()).min(5, 'Please select at least 5 favorite condiments').required('Listing favorite condiments is required'),
    lean: yup.number().required('Checking if lean or not is required'),
    wantsTestimonial: yup.number().required('Checking if permission to obtain testimonial is required'),
    waiverAcknowledged: yup.boolean()
      .required('Waiver acknowledgment is required')
      .oneOf([true], 'You must acknowledge the waiver before proceeding'),
    // Add other fields as needed
  }).required();

  const steps = ['General Information', 'Lifestyle Information', 'Other Information'];

  const favProteinSourceChoices = [
    "Lean Beef", "Shrimp", "Crab", "Cod", "Egg Whites", "Tofu", "Steak (Top Round or Tenderloin)", 
    "Lamb", "Goat", "Kangaroo", "Mackerel", "Black Beans (Cooked/Canned)", "Bison", "Catfish", 
    "Venison", "Chicken", "Turkey", "Lentils", "Tempeh", "Lean Ground Beef (93/7)", "Duck", 
    "Chickpea Pasta", "Sardines", "Ostrich", "Tuna", "Clam", "Salmon", "Chickpeas (Canned & Drained)", 
    "Pork", "Whey Protein", "Seitan", "Lean Ground Turkey (93/7)", "Veal", "Pea Protein", "Caribou", "Tilapia"
  ];

  const favCarbSourceChoices = [
    "All Bran Cereal", "Cream of Rice", "Cream of Wheat", "Wheat Pasta",
    "Sprouted Bread", "Berries", "Grits", "Black Beans", "Honey",
    "Brown Rice", "Jasmine Rice", "Buckwheat", "Barley", "Hummus",
    "Butternut Squash", "Cantaloupe", "Pineapple", "Pinto Beans",
    "Whole Wheat Pasta", "Potato", "Sweet Potato", "Basmati Rice",
    "Quinoa", "Oatmeal", "Apples", "Oranges", "Red Kidney Beans",
    "Carbo Complex Supplement", "Sweet Corn"
  ];

  const favVegSourceChoices = [
    "Spinach", "Kale", "Asparagus", "Mushrooms",
    "Cauliflower", "Bell Peppers", "Leeks", "Lettuce",
    "Snow Peas", "Zucchini", "Carrots", "Cabbage",
    "Eggplant", "Onions", "Seaweed", "Broccoli",
    "Brussel Sprouts", "Celery", "Cucumber", "Green Beans",
    "Okra", "Kohlrabi", "Turnips"
  ];

  const favFatSourceChoices = [
    "Raw Pumpkin Seeds", "Cashews", "Avocado", "Coconut Oil", "Hazelnuts",
    "Almond Butter", "Chia Seeds", "Walnut Oil", "Almonds", "Sesame Oil",
    "Pecans", "Walnuts", "Grass-Fed Butter", "Pistachios", "Coconut Butter",
    "Brazil Nuts", "Olives", "Macademias", "Peanut Butter", "Olive Oil",
    "Peanuts", "Flaxseed Oil", "Macademia Nut Oil", "Cashew Butter", "Avocado Oil"
  ];

  const favCondimentSourceChoices = [
    "Ketchup", "TASTE Spicy Garlic Parm", "Parmesan Cheese", "1% Milk", "Almond Milk",
    "Mustard", "TASTE Hot Honey Sriracha", "American Cheese", "2% Milk", "Cashew Milk",
    "TASTE Smoked Cherrywood BBQ", "Swiss Cheese", "Whole Milk"
  ];
  

const QuestionnaireComponent = ({ data }) => {
    const { control, handleSubmit, formState: { errors }, watch } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
          height: 50,
          weight: 1000,
          favProtein: [],
          favCarbs: [],
          favVeg: [],
          favFat: [],
          favCondiments: [],
          // set defaults for other fields as needed
        }
      });

    const checkSendToDifferentEmail = watch('sendToDifferentEmail');
    const checkGender = watch('gender');
    const checkKnowsBodyFat = watch('knowsBodyFat');
    const checkUnit = watch('unit');
    const checkIsCarbSensitive = watch('isCarbSensitive');
    
    // Update formData useState to include all the fields you mentioned
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        emailToConfirm: '',
        sendToDifferentEmail: '1',
        differentEmailToConfirm: '',
        differentEmail: '',
        instagram: '',
        phoneNumber: '',
        serviceHeard: '',
        gender: 0,
        isPregnant: 1,
        isBreastfeeding: 1,
        knowsBodyFat: 1,
        bodyFatPercent: 0,
        unit: 0,
        height: 0,
        weight: 0,
        age: 18,
        activityLevel: 0,
        experienceLevel: 0,
        workoutFrequency: 0,
        carbsOrFat: 0,
        numberOfMeals: 3,
        doesKeto: 1,
        doesCarbCycling: 1,
        isCarbSensitive: 1,
        prevCalories: 0,
        goal: 0,
        dietType: 1,
        dietPace: 0,
        workoutTime: 3,
        foodAllergies: '',
        injuries: '',
        medicalConditions: '',
        accessToGym: '',
        goalDescription: '',
        favProtein: '',
        favCarbs: '',
        favVeg: '',
        favFat: '',
        favCondiments: '',
        lean: 0,
        program: '',
        programLength: '',
        speaksPortuguese: 1,
        trainingRegiment: null,
        wantsTestimonial: 1
    });

  const [activeStep, setActiveStep] = useState(0);
  const [emailToConfirm, setEmailToConfirm] = useState('');
  const [sendToDifferentEmail, setSendToDifferentEmail] = useState('1');
  const [differentEmail, setDifferentEmail] = useState('');
  const [differentEmailToConfirm, setDifferentEmailToConfirm] = useState('');
  const [height, setHeight] = useState({ feet: 0, inches: 0, centimeters: 0 });
  const [weight, setWeight] = useState({ pounds: 0, kilograms: 0});
  const [feet, getFeet] = useState('');
  const [inches, getInches] = useState('');
  const [centimeters, getCentimeters] = useState('');
  const [pounds, getPounds] = useState('');
  const [kilograms, getKilograms] = useState('');
  const [selectedProteins, setSelectedProteins] = useState({});
  const [proteinError, setProteinError] = useState(false);
  const [selectedCarbs, setSelectedCarbs] = useState({});
  const [carbsError, setCarbsError] = useState(false);
  const [selectedVeg, setSelectedVeg] = useState({});
  const [vegError, setVegError] = useState(false);
  const [selectedFat, setSelectedFat] = useState({});
  const [fatError, setFatError] = useState(false);
  const [selectedCondiment, setSelectedCondiment] = useState({});
  const [condimentError, setCondimentError] = useState(false);
  const [waiverOpen, setWaiverOpen] = useState(false);
  const [waiverAcknowledged, setWaiverAcknowledged] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');


  useEffect(() => {
    // Convert and update formData.height based on the selected unit
    if (checkUnit == '0') {
        const totalInches = height.feet * 12 + height.inches;
        setFormData({ ...formData, height: totalInches, weight: weight.pounds }); // Update formData.height for metric
    } else if (checkUnit == '1') {
        setFormData({ ...formData, height: height.centimeters, weight: weight.kilograms }); // Update formData.height for imperial
    }
}, [height, weight, formData.unit]); // React to changes in unit or height


  const handleNext = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === steps.length - 1) {
        console.log(formData);
    }
  };
  
  const handleBack = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const handleReset = () => {
    window.scrollTo(0, 0);
    setActiveStep(0);
  };

  const handleClickOpen = () => {
    setWaiverOpen(true);
  };

  const handleClose = () => {
    setWaiverOpen(false);
  };

  // Modify the handleChange function
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

   /*  // Handle controlled inputs
    if (name in formData) {
        setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    } */

    // Special handling for height inputs and unit conversion
    if (name === "feet" || name === "inches" || name === "centimeters") {
      setHeight({ ...height, [name]: parseInt(value) });
    } else if (name === "pounds" || name === "kilograms") {
      setWeight({...weight, [name]: parseInt(value)});
    } else if (name === "unit") {
      setFormData({ ...formData, [name]: value });
      // Reset height inputs when unit changes
      setHeight({ feet: 0, inches: 0, centimeters: 0 });
      setWeight({ pounds: 0, kilograms: 0});
    } else {
      setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    }
  };

  const handleProteinChange = (event) => {
    setSelectedProteins({ ...selectedProteins, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    const selectedCount = Object.values(selectedProteins).filter(value => value).length;
    setProteinError(selectedCount < 5);
  }, [selectedProteins]);

  const handleCarbsChange = (event) => {
    setSelectedCarbs({ ...selectedCarbs, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    const selectedCount = Object.values(selectedCarbs).filter(value => value).length;
    setCarbsError(selectedCount < 5);
  }, [selectedCarbs]);

  const handleVegChange = (event) => {
    setSelectedVeg({ ...selectedVeg, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    const selectedCount = Object.values(selectedVeg).filter(value => value).length;
    setVegError(selectedCount < 5);
  }, [selectedVeg]);

  const handleFatChange = (event) => {
    setSelectedFat({ ...selectedFat, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    const selectedCount = Object.values(selectedFat).filter(value => value).length;
    setFatError(selectedCount < 5);
  }, [selectedFat]);

  const handleCondimentChange = (event) => {
    setSelectedCondiment({ ...selectedCondiment, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    const selectedCount = Object.values(selectedCondiment).filter(value => value).length;
    setCondimentError(selectedCount < 5);
  }, [selectedCondiment]);

  const handleWaiverAcknowledgedChange = (event) => {
    setWaiverAcknowledged(!waiverAcknowledged);
  };
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const onSubmit = data => {
    console.log(data);
    if(data.sendToDifferentEmail == '0') {
      data.email = data.differentEmail;
      delete data.differentEmail;
      delete data.differentEmailToConfirm;
    }
    delete data.emailToConfirm;
    data.height = formData.height;
    data.weight = formData.weight;
    data.favProtein = data.favProtein.join(', ');
    data.favCarbs = data.favCarbs.join(', ');
    data.favVeg = data.favVeg.join(', ');
    data.favFat = data.favFat.join(', ');
    data.favCondiments = data.favCondiments.join(', ');
    data.sendForm = true;

    setFormData(data);
  };

  useEffect(() => {
    const handleSubmitData = async () => {
      console.log(formData);
      if (formData.sendForm === true) {
        formData.clientStatus = 1;
        try {
          if (formData.age < 18) {
            const response = await axios.post('https://www.joeyswollcoaching.com/api/parental-consent.php', formData);
            if (response.status === 200) {
              console.log('Customer underage! E-mail sent for parental consent form.');
            }
          } else {
            const checkCustomerExists = await axios.get('https://www.joeyswollcoaching.com/api/request-handler.php', {
              params: {
                action: 'checkIfCustomerExists',
                email: formData.email
              },
              headers: {
                'Content-Type': 'application/json',
              }
            });
  
            if (checkCustomerExists.data.length > 0) {
              formData.id = checkCustomerExists.data[0].id;
              console.log(checkCustomerExists.data);
              console.log(formData.email + ' is in the system! Updating database entry with questionnaire information..');
              formData.action = "updateCustomerInfo";
              const updateCustomerInfo = await axios.post('https://www.joeyswollcoaching.com/api/request-handler.php', JSON.stringify(formData), {
                headers: {
                  'Content-Type': 'application/json'
                }
              });
              console.log(updateCustomerInfo.data);
            } else {
              console.log(formData.email + ' does not exist in database, inserting new data...');
              formData.action = "insertCustomerInfo";
              const response = await axios.post('https://www.joeyswollcoaching.com/api/request-handler.php', JSON.stringify(formData), {
                headers: {
                  'Content-Type': 'application/json'
                }
              });
              // Handle your response data here
              formData.id = response.data[0].id;
              console.log(response.data);
            }

            const assignClientToCoach = await axios.post('https://www.joeyswollcoaching.com/api/request-handler.php', JSON.stringify({
              action: 'assignCoachToClient',//actions.properties[actions.UPDATE_CLIENT_STATUS].value,
              customerId: formData.id,
              coachId: 6
            }), {
                headers: {
                  'Content-Type': 'application/json'
                }
            });

            console.log(assignClientToCoach.data);

            setSnackbarMessage('Form submitted successfully!');
            setSnackbarOpen(true);
            setSnackbarSeverity('success');

            // Use .href for better compatibility
            window.location.href = "https://www.joeyswollcoaching.com/questionnaire-submitted-successfully.php";
          }
        } catch (error) {
          console.error('Error:', error);
          let errorMessage = 'Error submitting form.';
  
          // Check if the error is due to an Axios response
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            
            // Use the server-provided error message or a default message
            errorMessage += ' Server responded: ' + (error.response.data.message || 'Please try again later.');
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            errorMessage += ' No response from server.';
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            errorMessage += ' ' + error.message;
          }

          setSnackbarMessage(errorMessage);
          setSnackbarOpen(true);
          setSnackbarSeverity('error');
        }
      }
    };
  
    handleSubmitData();
  }, [formData]); // Include all dependencies in this array

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      console.log('Form errors:', errors);
      console.log(formData);
    }
  }, [errors]);
  
  return (
    <Container>
        <Box
            sx={{
            position: 'relative',
            width: '100%', // Ensures the container fits the width of its parent
            height: 'auto', // Adjust based on your image aspect ratio or content
            marginBottom: 2, // Space below the image
            }}
        >
        {/* Image */}
        <Box
          component="img"
          sx={{
            width: '100%', // Make the image responsive
            height: 'auto', // Maintain aspect ratio
            display: 'block', // Removes bottom space
          }}
          src="https://cdn.shopify.com/s/files/1/0101/2046/2400/files/JOEY_HERO_questionnaire.png?3966579060803343689"
          alt="Questionnaire Header"
        />
        
        {/* Overlay Text */}
        <Box
            sx={{
                position: 'absolute',
                top: 0, // Adjust these values based on your design
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white', // Text color
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
                // Example of responsive typography
                '& .MuiTypography-h2': {
                fontSize: {
                    xs: '1.5rem', // smaller font size for extra-small devices
                    sm: '2.125rem', // default size for small devices and up
                },
                },
                '& .MuiTypography-subtitle1': {
                fontSize: {
                    xs: '0.875rem', // smaller font size for extra-small devices
                    sm: '1rem', // default size for small devices and up
                },
                },
            }}
          >
          <Typography variant="h2" component="h1" gutterBottom sx={{textAlign: 'center' }}>
            Welcome to my team!
          </Typography>
          <Typography variant="subtitle1" component="div" sx={{textAlign: 'center' }}>
            Thank you for letting me join your fitness journey.
          </Typography>
          <Typography variant="subtitle1" component="div" sx={{textAlign: 'center' }}>
            Please fill out the questionnaire in its entirety below so I can tailor your plan to best suit your needs!
          </Typography>
        </Box>
      </Box>
      <Paper
          sx={{
            elevation: 2,
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#CCCCCC'
          }}
        >
          <Typography variant="body2" component="div" gutterBottom sx={{textAlign: 'center', padding: 1, fontWeight: 'bold'}}>
            NOTE: PLEASE FILL OUT ALL FIELDS, OR THE SYSTEM WILL NOT LET YOU SUBMIT THE QUESTIONNAIRE.
          </Typography>
          <Typography variant="body2" component="div" gutterBottom sx={{ textAlign: 'center', padding: 1, fontWeight: 'bold' }}>
            ALL THE INFORMATION IS NEEDED TO EFFECTIVELY PROGRAM AND MEET YOUR NEEDS.
          </Typography>
          <Typography variant="body2" component="div" gutterBottom sx={{ textAlign: 'center', padding: 1, fontWeight: 'bold' }}>
            THE PROGRAM WILL START 3 DAYS AFTER THE PLAN IS SENT. IF YOU WANT TO START AT A SPECIFIC DATE, PLEASE LET ME KNOW.

            THANK YOU!
          </Typography>
        </Paper>

      <Stepper sx={{padding: 2}} activeStep={activeStep} orientation="horizontal">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        // Render a completion message or button
        <div>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset}>Reset</Button>
        </div>
      ) : (
        // Render form sections based on the active step
        <form onSubmit={handleSubmit(onSubmit)}>
          {activeStep === 0 && (
            <Card>
              <CardContent>
                {/* General Information Fields */}
                <Grid container spacing={2}>
                  {/* Use Grid item xs={12} sm={6} for each input to make it responsive */}
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="firstName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => <TextField {...field} label="First Name" variant="outlined" fullWidth error={!!errors.firstName} helperText={errors.firstName?.message} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="lastName"
                      control={control}
                      defaultValue=""
                      render={({ field }) => <TextField {...field} label="Last Name" variant="outlined" fullWidth error={!!errors.lastName} helperText={errors.lastName?.message} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({ field }) => <TextField {...field} label="What e-mail was this purchased with?" variant="outlined" fullWidth error={!!errors.email} helperText={errors.email?.message} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="emailToConfirm"
                      control={control}
                      defaultValue=""
                      render={({ field }) => <TextField {...field} label="Confirm E-mail Address" variant="outlined" fullWidth error={!!errors.emailToConfirm} helperText={errors.emailToConfirm?.message} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} display='flex'>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // Center horizontally
                        justifyContent: 'center', // Center vertically
                        width: '100%', // Ensure it takes the full width of the parent container
                      }}
                    >
                      <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Do you want the plan sent to a different email?
                      </Typography>
                      <Controller
                        name="sendToDifferentEmail"
                        control={control}
                        defaultValue="1"
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            row
                            /* value={formData.sendToDifferentEmail}
                            onChange={handleChange} */
                            error={!!errors.sendToDifferentEmail}
                          >
                            <FormControlLabel value="0" control={<Radio />} label="Yes" />
                            <FormControlLabel value="1" control={<Radio />} label="No" />
                          </RadioGroup>
                        )}
                      />
                      {errors.sendToDifferentEmail && (
                        <Typography color="error" variant="caption">
                          {errors.sendToDifferentEmail.message}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                {checkSendToDifferentEmail == "0" && (
                    <Grid item xs={12}>
                      <Grid container spacing={2}> {/* Ensure there's spacing between the text fields */}
                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="differentEmail"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField {...field} label="E-mail to Send Meal Plan To" variant="outlined" fullWidth error={!!errors.differentEmail} helperText={errors.differentEmail?.message} />}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Controller
                            name="differentEmailToConfirm"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <TextField {...field} label="Confirm new E-mail" variant="outlined" fullWidth error={!!errors.differentEmailToConfirm} helperText={errors.differentEmailToConfirm?.message} />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                )}
                <Grid item xs={12} sm={6}>
                    <Controller
                      name="instagram"
                      control={control}
                      defaultValue=""
                      render={({ field }) => 
                        <TextField {...field} 
                          label="Instagram" 
                          variant="outlined" 
                          fullWidth 
                          error={!!errors.instagram} 
                          helperText={errors.instagram?.message}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">@</InputAdornment>
                            ),
                          }} 
                        />
                      }
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      defaultValue=""
                      render={({ field }) => <TextField {...field} label="Phone Number" variant="outlined" fullWidth error={!!errors.phoneNumber} helperText={errors.phoneNumber?.message} />}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Controller
                      name="serviceHeard"
                      control={control}
                      defaultValue=""
                      render={({ field }) => <TextField {...field} label="How did you hear about this service?" variant="outlined" fullWidth multiline rows='5' error={!!errors.serviceHeard} helperText={errors.serviceHeard?.message} />}
                    />
                </Grid>

                <Grid item xs={12} sm={12} display='flex'>
                    <Box
                        sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // Center horizontally
                        justifyContent: 'center', // Center vertically (if you have a specific height)
                        width: '100%', // Ensure it takes the full width of the parent container
                        // Optional: if you want to set a specific height for the box to center vertically
                        // height: '100%',
                        }}
                    >
                      <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                          Gender
                      </Typography>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue="0"
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            row
                            name="gender"
                            /* value={formData.gender}
                            onChange={handleChange} */
                          >
                            <FormControlLabel value="0" control={<Radio />} label="Male" />
                            <FormControlLabel value="1" control={<Radio />} label="Female" />
                          </RadioGroup>
                        )}
                      />
                    </Box>
                  </Grid>

                  {checkGender == "1" && (
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} display="flex" justifyContent="center">
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center', // Center horizontally
                          justifyContent: 'center', // Center vertically (if you have a specific height)
                          width: '100%', // Ensure it takes the full width of the parent container
                        }}
                      >
                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                          Are you pregnant?
                        </Typography>
                        <Controller
                          name="isPregnant"
                          control={control}
                          defaultValue="1"
                          render={({ field }) => (
                            <RadioGroup
                              {...field}
                              row
                              name="isPregnant"
                              /* value={formData.isPregnant}
                              onChange={handleChange} */
                            >
                              <FormControlLabel value="0" control={<Radio />} label="Yes" />
                              <FormControlLabel value="1" control={<Radio />} label="No" />
                            </RadioGroup>
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} display="flex" justifyContent="center">
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center', // Center horizontally
                          justifyContent: 'center', // Center vertically
                          width: '100%', // Ensure it takes the full width of the parent container
                        }}
                      >
                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                          Are you breastfeeding?
                        </Typography>
                        <Controller
                          name="isBreastfeeding"
                          control={control}
                          defaultValue="1"
                          render={({ field }) => (
                            <RadioGroup
                              {...field}
                              row
                              name="isBreastfeeding"
                              /* value={formData.isBreastfeeding}
                              onChange={handleChange} */
                            >
                              <FormControlLabel value="0" control={<Radio />} label="Yes" />
                              <FormControlLabel value="1" control={<Radio />} label="No" />
                            </RadioGroup>
                          )}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                )}

                  <Grid item xs={12} sm={12} display='flex'>
                    <Box
                        sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // Center horizontally
                        justifyContent: 'center', // Center vertically (if you have a specific height)
                        width: '100%', // Ensure it takes the full width of the parent container
                        // Optional: if you want to set a specific height for the box to center vertically
                        // height: '100%',
                        }}
                    >
                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Do you consider yourself lean?
                        </Typography>
                        <Controller
                          name="lean"
                          control={control}
                          defaultValue="0"
                          render={({ field }) => (
                            <RadioGroup
                              {...field}
                              row
                              name="lean"
                              /* value={formData.lean}
                              onChange={handleChange} */
                            >
                              <FormControlLabel value="0" control={<Radio />} label="Yes" />
                              <FormControlLabel value="1" control={<Radio />} label="No" />
                            </RadioGroup>
                          )}
                        />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} display='flex'>
                    <Box
                        sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // Center horizontally
                        justifyContent: 'center', // Center vertically (if you have a specific height)
                        width: '100%', // Ensure it takes the full width of the parent container
                        // Optional: if you want to set a specific height for the box to center vertically
                        // height: '100%',
                        }}
                    >
                      <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                          Do you know your body fat percentage?
                      </Typography>
                      <Controller
                        name="knowsBodyFat"
                        control={control}
                        defaultValue="1"
                        render={({ field }) => (
                          <RadioGroup
                              {...field}
                              row
                              name="knowsBodyFat"
                              /* value={formData.knowsBodyFat}
                              onChange={handleChange} */
                          >
                            <FormControlLabel value="0" control={<Radio />} label="Yes" />
                            <FormControlLabel value="1" control={<Radio />} label="No" />
                          </RadioGroup>
                        )}
                      />
                    </Box>
                  </Grid>

                    {checkKnowsBodyFat == "0" && (
                        <Grid item xs={12} sm={12}>
                          <Controller
                            name="bodyFatPercent"
                            control={control}
                            defaultValue="0"
                            render={({ field }) => 
                              <TextField
                                {...field}
                                fullWidth
                                variant="outlined"
                                label="If so, what is your body fat percentage?"
                                name="bodyFatPercent"
                                /* value={formData.bodyFatPercent}
                                onChange={handleChange} */
                                margin="normal"
                              />
                            }
                          />
                        </Grid>
                        
                    )}

                <Grid item xs={12} sm={12} display='flex'>
                    <Box
                        sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // Center horizontally
                        justifyContent: 'center', // Center vertically (if you have a specific height)
                        width: '100%', // Ensure it takes the full width of the parent container
                        // Optional: if you want to set a specific height for the box to center vertically
                        // height: '100%',
                        }}
                    >
                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Unit of Measure
                        </Typography>
                        <Controller
                          name="unit"
                          control={control}
                          defaultValue="0"
                          render={({ field }) => (
                            <RadioGroup
                                {...field}
                                row
                                name="unit"
                                /* value={formData.unit}
                                onChange={handleChange} */
                            >
                                <FormControlLabel value="0" control={<Radio />} label="in/lbs (Imperial)" />
                                <FormControlLabel value="1" control={<Radio />} label="cm/kg (Metric)" />
                            </RadioGroup>
                          )}
                        />
                    </Box>
                  </Grid>

                  <Grid container spacing={2} item xs={12}>
                        {checkUnit == "0" ? (
                            <>
                            {/* Height: Feet and Inches stacked in one column */}
                            <Grid item xs={12} sm={4}>
                                <Grid container direction="column">
                                <Grid item xs={12}>
                                    <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Feet"
                                    name="feet"
                                    value={height.feet}
                                    onChange={handleChange}
                                    margin="normal"
                                    type="number"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Inches"
                                    name="inches"
                                    value={height.inches}
                                    onChange={handleChange}
                                    margin="normal"
                                    type="number"
                                    />
                                </Grid>
                                </Grid>
                            </Grid>
                            
                            {/* Weight */}
                            <Grid item xs={12} sm={4}>
                                <TextField
                                fullWidth
                                variant="outlined"
                                label="Weight (lbs)"
                                name="pounds"
                                value={weight.pounds}
                                onChange={handleChange}
                                margin="normal"
                                type="number"
                                />
                            </Grid>
                            </>
                        ) : (
                            <>
                            {/* Height: Centimeters in one column */}
                            <Grid item xs={12} sm={4}>
                                <TextField
                                fullWidth
                                variant="outlined"
                                label="Centimeters"
                                name="centimeters"
                                value={height.centimeters}
                                onChange={handleChange}
                                margin="normal"
                                type="number"
                                />
                            </Grid>
                            
                            {/* Weight */}
                            <Grid item xs={12} sm={4}>
                                <TextField
                                fullWidth
                                variant="outlined"
                                label="Weight (kg)"
                                name="kilograms"
                                value={weight.kilograms}
                                onChange={handleChange}
                                margin="normal"
                                type="number"
                                />
                            </Grid>
                            </>
                        )}
                        
                        {/* Age */}
                        <Grid item xs={12} sm={4}>
                          <Controller
                            name="age"
                            control={control}
                            defaultValue="18"
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                variant="outlined"
                                label="Age"
                                name="age"
                               /*  value={formData.age}
                                onChange={handleChange} */
                                margin="normal"
                                type="number"
                              />
                            )}
                          />
                        </Grid>
                    </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
          {activeStep === 1 && (
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={12} sm={4} display='flex'>
                                <Box
                                    sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Center horizontally
                                    justifyContent: 'center', // Center vertically (if you have a specific height)
                                    width: '100%', // Ensure it takes the full width of the parent container
                                    // Optional: if you want to set a specific height for the box to center vertically
                                    // height: '100%',
                                    }}
                                >
                                  <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                      Activity Level
                                  </Typography>
                                  <Controller
                                    name="activityLevel"
                                    control={control}
                                    defaultValue="0"
                                    render={({ field }) => (
                                      <RadioGroup
                                          {...field}
                                          name="activityLevel"
                                         /*  value={formData.activityLevel}
                                          onChange={handleChange} */
                                      >
                                        <FormControlLabel value="0" control={<Radio />} label="Sedentary" />
                                        <FormControlLabel value="1" control={<Radio />} label="Lightly Active" />
                                        <FormControlLabel value="2" control={<Radio />} label="Moderately Active" />
                                        <FormControlLabel value="3" control={<Radio />} label="Very Active" />
                                        <FormControlLabel value="4" control={<Radio />} label="Extremely Active" />
                                      </RadioGroup>
                                    )}
                                  />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} display='flex'>
                                <Box
                                    sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Center horizontally
                                    justifyContent: 'center', // Center vertically (if you have a specific height)
                                    width: '100%', // Ensure it takes the full width of the parent container
                                    // Optional: if you want to set a specific height for the box to center vertically
                                    // height: '100%',
                                    }}
                                >
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                        How long have you been working out?
                                    </Typography>
                                    <Controller
                                      name="experienceLevel"
                                      control={control}
                                      defaultValue="0"
                                      render={({ field }) => (
                                      <RadioGroup
                                          {...field}
                                          name="experienceLevel"
                                          /* value={formData.experienceLevel}
                                          onChange={handleChange} */
                                      >
                                          <FormControlLabel value="0" control={<Radio />} label="0" />
                                          <FormControlLabel value="1" control={<Radio />} label="1-6 Months" />
                                          <FormControlLabel value="2" control={<Radio />} label="7-12 Months" />
                                          <FormControlLabel value="3" control={<Radio />} label="1-2 Years" />
                                          <FormControlLabel value="4" control={<Radio />} label="3+ Years" />
                                      </RadioGroup>
                                      )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} display='flex'>
                                <Box
                                    sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Center horizontally
                                    justifyContent: 'center', // Center vertically (if you have a specific height)
                                    width: '100%', // Ensure it takes the full width of the parent container
                                    // Optional: if you want to set a specific height for the box to center vertically
                                    // height: '100%',
                                    }}
                                >
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                        Workout Frequency
                                    </Typography>
                                    <Controller
                                      name="workoutFrequency"
                                      control={control}
                                      defaultValue="0"
                                      render={({ field }) => (
                                        <RadioGroup
                                          {...field}
                                          name="workoutFrequency"
                                          /* value={formData.workoutFrequency}
                                          onChange={handleChange} */
                                        >
                                          <FormControlLabel value="0" control={<Radio />} label="None" />
                                          <FormControlLabel value="1" control={<Radio />} label="1-2 times a week" />
                                          <FormControlLabel value="2" control={<Radio />} label="3-5 times a week" />
                                          <FormControlLabel value="3" control={<Radio />} label="6-7 times a week" />
                                          <FormControlLabel value="4" control={<Radio />} label="Two-a-days or extremely intense sessions" />
                                        </RadioGroup>
                                      )}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={12} sm={12} display='flex'>
                                <Box
                                    sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Center horizontally
                                    justifyContent: 'center', // Center vertically (if you have a specific height)
                                    width: '100%', // Ensure it takes the full width of the parent container
                                    // Optional: if you want to set a specific height for the box to center vertically
                                    // height: '100%',
                                    }}
                                >
                                  <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                      Do you prefer carbohydrate or fat sources?
                                  </Typography>
                                  <Controller
                                    name="carbsOrFat"
                                    control={control}
                                    defaultValue="0"
                                    render={({ field }) => (
                                      <RadioGroup
                                        {...field}
                                        row
                                        name="carbsOrFat"
                                        /* value={formData.carbsOrFat}
                                        onChange={handleChange} */
                                      >
                                        <FormControlLabel value="0" control={<Radio />} label="Carbohydrates" />
                                        <FormControlLabel value="1" control={<Radio />} label="Fats" />
                                        <FormControlLabel value="2" control={<Radio />} label="Neither" />
                                      </RadioGroup>
                                    )} 
                                  />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} display='flex'>
                                <Box
                                    sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Center horizontally
                                    justifyContent: 'center', // Center vertically (if you have a specific height)
                                    width: '100%', // Ensure it takes the full width of the parent container
                                    // Optional: if you want to set a specific height for the box to center vertically
                                    // height: '100%',
                                    }}
                                >
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                        Number of Meals per day
                                    </Typography>
                                    <Controller
                                      name="numberOfMeals"
                                      control={control}
                                      defaultValue="3"
                                      render={({ field }) => (
                                        <RadioGroup
                                          {...field}
                                          row
                                          name="numberOfMeals"
                                          /* value={formData.numberOfMeals}
                                          onChange={handleChange} */
                                        >
                                            <FormControlLabel value="1" control={<Radio />} label="1" />
                                            <FormControlLabel value="2" control={<Radio />} label="2" />
                                            <FormControlLabel value="3" control={<Radio />} label="3" />
                                            <FormControlLabel value="4" control={<Radio />} label="4" />
                                            <FormControlLabel value="5" control={<Radio />} label="5" />
                                            <FormControlLabel value="6" control={<Radio />} label="6" />
                                            <FormControlLabel value="7" control={<Radio />} label="7" />
                                        </RadioGroup>
                                      )}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} display='flex'>
                                <Box
                                    sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Center horizontally
                                    justifyContent: 'center', // Center vertically (if you have a specific height)
                                    width: '100%', // Ensure it takes the full width of the parent container
                                    // Optional: if you want to set a specific height for the box to center vertically
                                    // height: '100%',
                                    }}
                                >
                                  <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                      Do you usually follow a ketogenic diet?
                                  </Typography>
                                  <Controller
                                    name="doesKeto"
                                    control={control}
                                    defaultValue="1"
                                    render={({ field }) => (
                                      <RadioGroup
                                        {...field}
                                        row
                                        name="doesKeto"
                                        /* value={formData.doesKeto}
                                        onChange={handleChange} */
                                      >
                                        <FormControlLabel value="0" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="1" control={<Radio />} label="No" />
                                      </RadioGroup>
                                    )}
                                  />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} display='flex'>
                                <Box
                                    sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Center horizontally
                                    justifyContent: 'center', // Center vertically (if you have a specific height)
                                    width: '100%', // Ensure it takes the full width of the parent container
                                    // Optional: if you want to set a specific height for the box to center vertically
                                    // height: '100%',
                                    }}
                                >
                                  <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                      Are you carb-sensitive or coming off of a calorie-restricted diet?
                                  </Typography>
                                  <Controller
                                    name="isCarbSensitive"
                                    control={control}
                                    defaultValue="1"
                                    render={({ field }) => (
                                      <RadioGroup
                                        {...field}
                                          row
                                          name="isCarbSensitive"
                                          /* value={formData.isCarbSensitive}
                                          onChange={handleChange} */
                                      >
                                        <FormControlLabel value="0" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="1" control={<Radio />} label="No" />
                                      </RadioGroup>
                                    )}
                                  />
                                </Box>
                            </Grid>
                            
                            {checkIsCarbSensitive == "0" && (
                              <Grid item xs={12} sm={12} display="flex" justifyContent="center" alignItems="center">
                                <Controller
                                  name="prevCalories"
                                  control={control}
                                  defaultValue="0"
                                  render={({ field }) => 
                                    <TextField
                                      {...field}
                                      fullWidth
                                      variant="outlined"
                                      label="Current Caloric Intake"
                                      name="prevCalories"
                                      /* value={formData.prevCalories}
                                      onChange={handleChange} */
                                      margin="normal"
                                    />
                                  }
                                />
                              </Grid>
                            )}

                            <Grid item xs={12} sm={12} display='flex'>
                              <Box
                                  sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center', // Center horizontally
                                  justifyContent: 'center', // Center vertically (if you have a specific height)
                                  width: '100%', // Ensure it takes the full width of the parent container
                                  // Optional: if you want to set a specific height for the box to center vertically
                                  // height: '100%',
                                  }}
                              >
                                <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                    What is the main goal you are aiming for?
                                </Typography>
                                <Controller
                                  name="goal"
                                  control={control}
                                  defaultValue="1"
                                  render={({ field }) => (
                                    <RadioGroup
                                      {...field}
                                      row
                                      name="goal"
                                      /* value={formData.goal}
                                      onChange={handleChange} */
                                    >
                                      <FormControlLabel value="0" control={<Radio />} label="Lose Fat" />
                                      <FormControlLabel value="1" control={<Radio />} label="Build Muscle" />
                                      <FormControlLabel value="2" control={<Radio />} label="Restore Metabolism" />
                                    </RadioGroup>
                                  )}
                                />
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Controller
                                  name="goalDescription"
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => <TextField {...field} label="Describe your goals for this program in two sentences." variant="outlined" fullWidth multiline rows='5' error={!!errors.goalDescription} helperText={errors.goalDescription?.message} />}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} item xs={12} display="flex" alignItems="start">
                            <Grid item xs={12} sm={4} display='flex'>
                                <Box
                                    sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Center horizontally
                                    justifyContent: 'center', // Center vertically (if you have a specific height)
                                    width: '100%', // Ensure it takes the full width of the parent container
                                    // Optional: if you want to set a specific height for the box to center vertically
                                    // height: '100%',
                                    }}
                                >
                                  <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                  When do you usually work out?
                                  </Typography>
                                  <Controller
                                    name="workoutTime"
                                    control={control}
                                    defaultValue="0"
                                    render={({ field }) => (
                                      <RadioGroup
                                        {...field}
                                        name="workoutTime"
                                        value={formData.workoutTime}
                                        onChange={handleChange}
                                      >
                                        <FormControlLabel value="0" control={<Radio />} label="Morning" />
                                        <FormControlLabel value="1" control={<Radio />} label="Lunch Time" />
                                        <FormControlLabel value="2" control={<Radio />} label="Early Afternoon" />
                                        <FormControlLabel value="3" control={<Radio />} label="Late Afternoon" />
                                        <FormControlLabel value="4" control={<Radio />} label="Night Time" />
                                      </RadioGroup>
                                    )}
                                  />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} display='flex'>
                                <Box
                                    sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Center horizontally
                                    justifyContent: 'center', // Center vertically (if you have a specific height)
                                    width: '100%', // Ensure it takes the full width of the parent container
                                    // Optional: if you want to set a specific height for the box to center vertically
                                    // height: '100%',
                                    }}
                                >
                                  <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                      Diet Type
                                  </Typography>
                                  <Controller
                                    name="dietType"
                                    control={control}
                                    defaultValue="0"
                                    render={({ field }) => (
                                      <RadioGroup
                                        {...field}
                                        name="dietType"
                                       /*  value={formData.dietType}
                                        onChange={handleChange} */
                                      >
                                          <FormControlLabel value="0" control={<Radio />} label="Traditional" />
                                          <FormControlLabel value="1" control={<Radio />} label="Flexible" />
                                      </RadioGroup>
                                    )}
                                  />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} display='flex'>
                                <Box
                                    sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Center horizontally
                                    justifyContent: 'center', // Center vertically (if you have a specific height)
                                    width: '100%', // Ensure it takes the full width of the parent container
                                    // Optional: if you want to set a specific height for the box to center vertically
                                    // height: '100%',
                                    }}
                                >
                                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                      Would you like to go at a moderate pace (slower, more sustainable, fat loss-optimal) or at a quick pace (fast results, increased risk of rebound, more strict)?
                                    </Typography>
                                    <Controller
                                      name="dietPace"
                                      control={control}
                                      defaultValue="0"
                                      render={({ field }) => (
                                        <RadioGroup
                                          {...field}
                                          name="dietPace"
                                          /* value={formData.dietPace}
                                          onChange={handleChange} */
                                        >
                                          <FormControlLabel value="0" control={<Radio />} label="Moderate" />
                                          <FormControlLabel value="1" control={<Radio />} label="Quick" />
                                        </RadioGroup>
                                      )}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
          )}
          {activeStep === 2 && (
            <Card>
                <CardContent>
                    <Grid container padding="1" spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Controller
                              name="foodAllergies"
                              control={control}
                              defaultValue=""
                              render={({ field }) => <TextField {...field} label="If you have food allergies, please list them." variant="outlined" fullWidth multiline rows='5' error={!!errors.foodAllergies} helperText={errors.foodAllergies?.message} />}
                            />
                        </Grid>
                    
                        <Grid item xs={12} sm={12}>
                            <Controller
                              name="injuries"
                              control={control}
                              defaultValue=""
                              render={({ field }) => <TextField {...field} label="If you have injuries or medical conditions, please list them." variant="outlined" fullWidth multiline rows='5' error={!!errors.injuries} helperText={errors.injuries?.message} />}
                            />
                        </Grid>
                    
                        <Grid item xs={12} sm={12}>
                            <Controller
                              name="medicalConditions"
                              control={control}
                              defaultValue=""
                              render={({ field }) => <TextField {...field} label="Do you have anything I should know before making your diet plan?" variant="outlined" fullWidth multiline rows='5' error={!!errors.medicalConditions} helperText={errors.medicalConditions?.message} />}
                            />
                        </Grid>
                    
                        <Grid item xs={12} sm={12}>
                            <Controller
                              name="accessToGym"
                              control={control}
                              defaultValue=""
                              render={({ field }) => <TextField {...field} label="Do you have limited equipment/no access to a gym? If yes, please enter what you have below" variant="outlined" fullWidth multiline rows='5' error={!!errors.accessToGym} helperText={errors.accessToGym?.message} />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h6" component="div" sx={{ textAlign: 'center' }}>Select Your Favorite Protein Sources (Choose at least 5)</Typography>
                            <Grid container>
                              <Controller
                                control={control}
                                name="favProtein"
                                rules={{ required: true, validate: value => value.length >= 5 || 'Please select at least 5 favorite proteins' }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <React.Fragment>
                                    <Grid container>
                                      {favProteinSourceChoices.map(protein => (
                                        <Grid item xs={12} sm={4} key={protein}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={value.includes(protein)}
                                                onChange={(e) => {
                                                  const newValue = e.target.checked
                                                    ? [...value, protein]
                                                    : value.filter(val => val !== protein);
                                                  onChange(newValue); // Update React Hook Form
                                                }}
                                              />
                                            }
                                            label={protein}
                                          />
                                        </Grid>
                                      ))}
                                    </Grid>
                                    {error && (
                                      <FormHelperText error>{error.message}</FormHelperText>
                                    )}
                                  </React.Fragment>
                                )}
                              />
                            </Grid>
                            {/* {proteinError && <FormHelperText error sx={{ textAlign: 'right' }}>Please select at least 5 protein sources.</FormHelperText>} */}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h6" component="div" sx={{ textAlign: 'center' }}>Select Your Favorite Carbohydrate Sources (Choose at least 5)</Typography>
                            <Grid container>
                              <Controller
                                  control={control}
                                  name="favCarbs"
                                  rules={{ required: true, validate: value => value.length >= 5 || 'Please select at least 5 favorite carbohydrates' }}
                                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <React.Fragment>
                                      <Grid container>
                                        {favCarbSourceChoices.map(carb => (
                                          <Grid item xs={12} sm={4} key={carb}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={value.includes(carb)}
                                                  onChange={(e) => {
                                                    const newValue = e.target.checked
                                                      ? [...value, carb]
                                                      : value.filter(val => val !== carb);
                                                    onChange(newValue); // Update React Hook Form
                                                  }}
                                                />
                                              }
                                              label={carb}
                                            />
                                          </Grid>
                                        ))}
                                      </Grid>
                                      {error && (
                                        <FormHelperText error>{error.message}</FormHelperText>
                                      )}
                                    </React.Fragment>
                                  )}
                                />
                            </Grid>
                            {/* {carbsError && <FormHelperText error sx={{ textAlign: 'right' }}>Please select at least 5 carbohydrate sources.</FormHelperText>} */}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h6" component="div" sx={{ textAlign: 'center' }}>Select Your Favorite Vegetable Sources (Choose at least 5)</Typography>
                            <Grid container>
                              <Controller
                                  control={control}
                                  name="favVeg"
                                  rules={{ required: true, validate: value => value.length >= 5 || 'Please select at least 5 favorite vegetables' }}
                                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <React.Fragment>
                                      <Grid container>
                                        {favVegSourceChoices.map(veg => (
                                          <Grid item xs={12} sm={4} key={veg}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={value.includes(veg)}
                                                  onChange={(e) => {
                                                    const newValue = e.target.checked
                                                      ? [...value, veg]
                                                      : value.filter(val => val !== veg);
                                                    onChange(newValue); // Update React Hook Form
                                                  }}
                                                />
                                              }
                                              label={veg}
                                            />
                                          </Grid>
                                        ))}
                                      </Grid>
                                      {error && (
                                        <FormHelperText error>{error.message}</FormHelperText>
                                      )}
                                    </React.Fragment>
                                  )}
                                />
                            </Grid>
                            {/* {vegError && <FormHelperText error sx={{ textAlign: 'right' }}>Please select at least 5 vegetable sources.</FormHelperText>} */}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h6" component="div" sx={{ textAlign: 'center' }}>Select Your Favorite Fat Sources (Choose at least 5)</Typography>
                            <Grid container>
                              <Controller
                                  control={control}
                                  name="favFat"
                                  rules={{ required: true, validate: value => value.length >= 5 || 'Please select at least 5 favorite fats' }}
                                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <React.Fragment>
                                      <Grid container>
                                        {favFatSourceChoices.map(fat => (
                                          <Grid item xs={12} sm={4} key={fat}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={value.includes(fat)}
                                                  onChange={(e) => {
                                                    const newValue = e.target.checked
                                                      ? [...value, fat]
                                                      : value.filter(val => val !== fat);
                                                    onChange(newValue); // Update React Hook Form
                                                  }}
                                                />
                                              }
                                              label={fat}
                                            />
                                          </Grid>
                                        ))}
                                      </Grid>
                                      {error && (
                                        <FormHelperText error>{error.message}</FormHelperText>
                                      )}
                                    </React.Fragment>
                                  )}
                                />
                            </Grid>
                            {/* {fatError && <FormHelperText error sx={{ textAlign: 'right' }}>Please select at least 5 fat sources.</FormHelperText>} */}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h6" component="div" sx={{ textAlign: 'center' }}>Select Your Favorite Condiments (Choose at least 5)</Typography>
                            <Grid container>
                              <Controller
                                  control={control}
                                  name="favCondiments"
                                  rules={{ required: true, validate: value => value.length >= 5 || 'Please select at least 5 favorite condiments' }}
                                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <React.Fragment>
                                      <Grid container>
                                        {favCondimentSourceChoices.map(condiment => (
                                          <Grid item xs={12} sm={4} key={condiment}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={value.includes(condiment)}
                                                  onChange={(e) => {
                                                    const newValue = e.target.checked
                                                      ? [...value, condiment]
                                                      : value.filter(val => val !== condiment);
                                                    onChange(newValue); // Update React Hook Form
                                                  }}
                                                />
                                              }
                                              label={condiment}
                                            />
                                          </Grid>
                                        ))}
                                      </Grid>
                                      {error && (
                                        <FormHelperText error>{error.message}</FormHelperText>
                                      )}
                                    </React.Fragment>
                                  )}
                                />
                            </Grid>
                            {/* {condimentError && <FormHelperText error sx={{ textAlign: 'right' }}>Please select at least 5 condiment sources.</FormHelperText>} */}
                        </Grid>
                        <Grid item xs={12} sm={12} display='flex'>
                            <Box
                                sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center', // Center horizontally
                                justifyContent: 'center', // Center vertically (if you have a specific height)
                                width: '100%', // Ensure it takes the full width of the parent container
                                // Optional: if you want to set a specific height for the box to center vertically
                                // height: '100%',
                                }}
                            >
                              <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                  Would you like your transformation to be put on my page?
                              </Typography>
                              <Controller
                                  name="wantsTestimonial"
                                  control={control}
                                  defaultValue="1"
                                  render={({ field }) => (
                                    <RadioGroup
                                      {...field}
                                      row
                                      name="wantsTestimonial"
                                      /* value={formData.wantsTestimonial}
                                      onChange={handleChange} */
                                    >
                                      <FormControlLabel value="0" control={<Radio />} label="Yes" />
                                      <FormControlLabel value="1" control={<Radio />} label="No" />
                                    </RadioGroup>
                                  )}
                              />
                            </Box>
                        </Grid>
                        <Paper
                            sx={{
                                elevation: 2,
                                width: '100%',
                                height: 'auto',
                                display: 'flex',
                                margin: 1,
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#CCCCCC'
                            }}
                            >
                              <Controller
                                  name="waiverAcknowledged"
                                  control={control}
                                  defaultValue={false}
                                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Grid display="flex" direction="column" justifyContent="center" alignItems="center" >
                                      <FormControlLabel
                                        sx={{ margin: 2 }}
                                        control={
                                          <Checkbox
                                            checked={value} // Controlled by React Hook Form
                                            onChange={e => onChange(e.target.checked)} // Update form state
                                            name="waiverAcknowledged"
                                          />
                                        }
                                        label={`BY CLICKING THIS CHECKBOX, I ACKNOWLEDGE THAT I AM NO LONGER ELIGIBLE FOR A FULL REFUND AND I HAVE THOROUGHLY READ THE FITNESS WAIVER IN ITS ENTIRETY AND FULLY UNDERSTAND THAT IT IS A RELEASE OF LIABILITY. BY SUBMITTING THE QUESTIONNAIRE FORM, I AM ACKNOWLEDGING THAT I AM WAIVING ANY RIGHT I OR MY SUCCESSORS MIGHT HAVE TO BRING A LEGAL ACTION OR ASSERT A CLAIM AGAINST TRAINER FOR YOUR NEGLIGENCE OR THAT OF TALENT’S EMPLOYEES, AGENTS, OR CONTRACTORS.`}
                                      />
                                      {error && <FormHelperText error>{error.message}</FormHelperText>}
                                    </Grid>
                                  )}
                              />
                              
                              <Button color="primary" variant="contained" onClick={handleClickOpen} sx={{ margin: 1}}>
                                  Show Waiver
                              </Button>
                        </Paper>
                    </Grid>
                </CardContent>
            </Card>
          )}
          {Object.keys(errors).length > 0 ? (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{margin: 2}}>
                <Typography sx={{ color: 'red'}}>
                  You still have errors in your questionnaire. Please go back through it and fix the errors.
                </Typography>                     
            </Box>) : null }   
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              variant="contained" 
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button 
                variant="contained" 
                disabled={(activeStep === steps.length - 1 && Object.keys(errors).length > 0)}
                onClick={/* handleSubmit(onSubmit) */activeStep === steps.length - 1 ? handleSubmit(onSubmit) : handleNext}
            >
                {activeStep === steps.length - 1 ? 'Submit Questionnaire' : 'Next'}
            </Button>
          </Box>
        </form>
      )}
      <Dialog
        open={waiverOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center', fontWeight: 'bold'}}>{"Customized Plan Waiver"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ whiteSpace: "pre-line" }}>
            {`I have volunteered to participate in a fitness program (workout plan and/or diet plan) provided to me by Joey or their agent, affiliate, etc., (“Trainer”), which may include, but may not be limited to, resistance training, aerobic exercise, cardiovascular exercise, meal plan(s), or the like. In consideration of Trainer’s agreement to provide me a customized plan, I do here now and forever release and discharge and hereby hold harmless Trainer and Trainer’s respective agents, heirs, assigns, contractors, and employees from any and all claims, demands, damages, rights of action or causes of action, present or future, arising out of or connected with my participation in this or any exercise program including any injuries resulting there from. I also confirm that I am at least 18 years of age.

THIS WAIVER AND RELEASE OF LIABILITY INCLUDES, WITHOUT LIMITATION, INJURIES WHICH MAY OCCUR AS A RESULT OF (1) FOLLOWING THE PLAN PROVIDED BY TRAINER; (2) ANY SLIP, FALL, DROPPING OF EQUIPMENT; (3) AND/OR NEGLIGENT INSTRUCTION

I have been informed of, understand and am aware that any exercise program, whether or not requiring the use of exercise equipment, is a potentially hazardous activity. I also have been informed of, understand and am aware that any exercise and/or fitness activities involve a risk of injury, as well as abnormal changes in blood pressure, fainting, and a remote risk of heart attack, stroke, other serious disability or death, and that I am voluntarily participating in these activities with full knowledge, understanding and appreciation of the dangers involved. I hereby agree to expressly assume and accept any and all risks of injury, regardless of severity, or death. I have also been informed of, understand, and aware that the Trainer is not responsible for any injuries, allergies, or other adverse health events that occur as a result of a suggested diet plan. I am fully responsible for the ingredients used when following the diet plan and any negative effects of consuming the ingredients. I am also responsible for ensuring that I receive enough food and nutrients on a daily basis for my specific bodily needs.

I have been advised that an examination by a physician should be obtained by anyone prior to commencing a fitness and/or exercise program, or initiating a substantial change in the amount of regular physical activity performed or food consumed. I have also consulted with a physician regarding any pre-existing conditions, allergies, or concerns I have and am not relying on Trainer to account for any injury or condition I may now be recovering from or have had in the past. I will specifically consult with the physician regarding participating in the Trainer Provided customized plan prior to participating in the plan. If I have chosen not to obtain a physician’s consent prior to beginning this program with Trainer, I hereby agree that I am doing so solely at my own risk. In any event, I acknowledge and agree that I assume the risks associated with any and all fitness related activities and/or exercises in which I participate or food I consume/do not consume.`}

<br></br><br></br><b>This waiver is an important legal document that explains the risks you are assuming by beginning an exercise program. It is critical that you have read and understand this document completely. If you do not understand any part of this document, it is your ultimate responsibility to ask for clarification prior to submission.</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default QuestionnaireComponent;
